.container {
  position: fixed;
  bottom: 5rem;
  left: 1rem;
  width: 20em;
  height: 15rem;
  border-radius: 1rem;
  background-color: red;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  z-index: 5;

  .tilte {
    margin: 0.5rem;
    color: #FFFFFF;
    font-size: 1.5rem;
    text-align: center;
  }

  .ipData {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem;
    border-radius: 1rem;
    height: 11.3rem;
    background-color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8rem;
    text-align: center;

    .visitTitle{
      font-weight: 600;
      font-size: 1rem;
    }
  }

  table {
    th {
      width: 5rem;
    }

    td {
      padding: 0 0.5rem;
      width: 5rem;
      white-space: pre-line;
    }
  }
}

.content {
  margin-top: 0.5rem;
}

.yellowText{
  width: 5rem;
  color: #ffd000;
  font-weight: 600;
}


.closedBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  position: fixed;
  bottom: 5rem;
  left: 1rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  background-color: red;
  color: #FFFFFF;
  z-index: 5;
}

.closeButton {
  position: absolute;
  top: 0rem;
  right: 0.5rem;
  margin-top: 0.2rem;
  z-index: 3;
  cursor: pointer;
}
